import React, { useState } from 'react';

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-item">
      <button 
        className={`accordion-header ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <span className="accordion-icon">{isOpen ? '−' : '+'}</span>
      </button>
      <div className={`accordion-content ${isOpen ? 'open' : ''}`}>
        {answer}
      </div>
    </div>
  );
};

const Accordion = () => {
  const faqs = [
    {
      question: "What should you wear?",
      answer: "Tiki or Safari attire is strongly recommended but not required."
    },
    {
      question: "Where are we?",
      answer: "Near the intersection of Slauson and La Brea. The location will be texted to you the day of your reservation. Street parking is available, however we strongly encourage you to use Uber or Lyft. Please wait by the gate upon arrival and we will let you in."
    },
    {
      question: "Does anything contain allergens?",
      answer: "Some of our selections contain dairy or nuts. Currently we are unable to make any substitutions. Apologies for the inconvenience."
    },
    {
      question: "Are there any additional costs to the $75 fee?",
      answer: "No. The $75 fee covers the entire experience."
    },
    {
      question: "Is there food available?",
      answer: "No. We suggest you plan to eat before or after your experience with us."
    } 
  ];

  return (
    <div className="accordion-section">
      {faqs.map((faq, index) => (
        <AccordionItem 
          key={index}
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
  );
};

export default Accordion;